import React from 'react';
import '../../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Navbar2 from '../Navbar2';

function Blog() {
  return (
    <>
      <Navbar2/>
      <Footer/>
    </>
  );
}

export default Blog;
