import React from 'react';
import '../../../App.css';
import Navbar2 from '../Navbar2';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <Navbar2/>
      <HeroSection/>
      <Footer/>
    </>
  );
}

export default Home;
